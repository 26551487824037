.cta {
  padding: 96px 0 112px;
  background: linear-gradient(45deg, #2164C5 0%, #2874C6 47.92%, #0EB8C9 100%);
  color: var(--white);
  text-align: center
}

.title {
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.wrapper {
  max-width: 620px;
  margin: 0 auto;
}

.buttonWrapper {
  margin-top: 42px;
  text-align: center;
}

.icon {
  margin-bottom: 24px;
}
