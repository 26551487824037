.logo {
    position: relative;
    width: 160px;
    height: 68px;
}

@media screen and (max-width: 1024px) {
  .logo {
      position: relative;
      width: 92px;
      height: 39px;
  }
}
