.menuToggle {
  display: block;
  width: 32px;
  height: 22px;
  position: relative;
  z-index: 1000;
  display: none;
}

.menuToggle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--white);
}

.menuToggle:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--white);
}

.headerDark .menuToggle:before,
.headerDark .menuToggle:after,
.headerDark .menuToggle span {
  background: var(--black);
}

.menuToggle span {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  right: 0;
  height: 2px;
  background: var(--white);
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
}

.logo {
  z-index: 100;
}

.headerDark .navItem {
  color: var(--black);
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navItems {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 1024px) {
  .navItem {
    position: relative;
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 72px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navItemHasDropdown:after {
    margin-left: 12px;
    content: "";
    display: inline-block;
    width: 10px;
    height: 6px;
    background: url("./chevron.svg");
  }

  .headerDark .navItemHasDropdown:after {
    background: url("./chevronDark.svg");
  }

  .navItem {
    margin-right: 42px;
  }

  .chevron {
    display: none;
  }

  .dropdown,
  .dropdownSmall {
    box-shadow: 0 2px 20px 0 rgba(35,35,35,0.1);
    display: none;
    background: var(--white);
    border-radius: 12px;
    color: var(--black);
    line-height: 1.5rem;
  }

  .dropdown {
    width: 360px;
  }

  .navItem:hover .dropdown,
  .navItem:hover .dropdownSmall {
    display: block;
    top: 100%;
    position: absolute;
    left: -34px;
    text-transform: none;
  }

  .dropdownItem {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  .dropdownItem:hover svg,
  .dropdownItem:hover svg * {
    fill: var(--green);
  }

  .dropdown .dropdownItem:not(:first-child) {
    border-top: 1px solid var(--lighterGray);
  }

  .dropdownItem.dropdownItemWithIcon {
    border-top: 0px;
    font-weight: 500;
    padding-left: 24px;
  }

  .dropdownItem a {
    display: flex;
    align-items: center;
  }

  .dropdownItem:hover {
    color: var(--green);
  }

  .dropdown .icon {
    position: relative;
    top: 2px;
    margin-right: 22px;
    width: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .dropdown {
    color: #232323;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .dropdown .dropdownItem:not(.dropdownItemWithIcon):hover {
    text-decoration: underline;
    color: var(--black);
  }

  .dropdownSmall .dropdownItem {
    color: var(--black);
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 28px;
    font-weight: 400;
    padding: 5px 32px;
    line-height: 28px;
  }

  .dropdownSmall .dropdownItem:hover {
    text-decoration: underline;
  }

  .dropdownSmall {
    padding: 16px 0;
    width: 210px;
  }

  .buttonWrapperMobile {
    display: none;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 24px;
    color: var(--gray);
    text-transform: uppercase;
    padding: 16px 24px 12px 24px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1024px) {
  .nav,
  .navItems {
    flex-direction: column;
  }

  .dropdownSmall,
  .dropdown {
    display: block;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .navItem {
    position: relative;
  }

  .navItem .dropdown,
  .navItem .dropdownSmall {
    display: none;
  }

  .navItemOpen .dropdown,
  .navItemOpen .dropdownSmall {
    display: block;
    background: var(--offWhite);
  }

  .navItems {
    border-top: 1px solid var(--lighterGray);
    margin-left: auto;
    margin-right: auto;
  }

  .header .navItem {
    color: var(--black);
  }

  .navItem .icon {
    display: none;
  }

  .chevron {
    display: block;
    position: absolute;
    top: 22px;
    right: 32px;
  }

  .navItemOpen .chevron {
    transform: rotate(180deg);
  }

  .navItemTitle {
    border-bottom: 1px solid var(--lighterGray);
    display: block;
    padding: 12px 32px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .navItemTitle:before {
  }

  .dropdown,
  .dropdownSmall {
    padding: 6px 0;
  }

  .dropdownItem {
    padding: 6px 32px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 26px;
  }

  .navItemTitle {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .nav {
    display: none;
  }

  .nav.navOpen {
    display: block;
    position: fixed;
    overflow-y: scroll;
    max-height: 100vh;
    padding-top: 80px;
    padding-bottom: 32px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    right: 0;
    background: var(--white);
  }

  .menuToggleActive {
    position: fixed;
    right: 26px;
  }

  .menuToggle {
    display: block;
  }

  .buttonWrapper {
    display: none;
  }

  .buttonWrapperMobile {
    padding: 24px 32px 0;
  }

  .buttonWrapperMobile > * {
    width: 100%;
  }

  .menuToggleActive:before,
  .menuToggleActive:after,
  .menuToggleActive span {
    background: var(--black);
  }

  .menuToggleActive {
    transform: translateY(12px) translateX(2px);
  }

  .menuToggleActive:after {
    top: 0px;
    transform: rotate(45deg);
  }

  .menuToggleActive:before {
    bottom: 0px;
    transform: rotate(-45deg);
  }

  .menuToggleActive span {
    display: none;
  }

  .dropdown .dropdownItem:nth-child(3) {
    border-bottom: 1px solid var(--lighterGray);
    padding-bottom: 12px;
  }

  .dropdown .dropdownItem:nth-child(4) {
    padding-top: 12px;
  }

  .label {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 24px;
    color: var(--gray);
    text-transform: uppercase;
    margin-top: 6px;
    padding: 0 32px;
  }
}
