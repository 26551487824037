.label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  line-height: 32px;
  background: var(--white);
  color: var(--black);
  border-radius: 8px;
  display: inline-block;
  padding: 0 12px;
  text-transform: uppercase;
  margin-right: 16px;
}

.transparentBlack {
  background: transparent;
  color: var(--black);
}

.transparentWhite {
  background: transparent;
  color: var(--white);
}
