.footer {
  background: var(--black);
  padding: 48px 0 26px;
  position: sticky;
  bottom: 0;
  z-index: 0;
  right: 0;
  left: 0;
  z-index: 0;
}

.copyright {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  text-align: center;
  color: var(--white);
}

.about {
  width: 38.5%;
}

.aboutText {
  max-width: 300px;
  margin-top: 32px;
  color: var(--white);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.top {
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.topLinksWrapper {
  width: 18%;
}

.topLinksHeading {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  margin-bottom: 16px;
}

.topLinks li {
  color: var(--gray);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin-bottom: 12px;
}

.topLinks a:hover {
  text-decoration: underline;
}

.bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
}

.bottom:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -108px;
  right: -108px;
  height: 1px;
  background: var(--gray);
  opacity: 0.2;
}

.bottom > * {
  flex: 1 1 auto;
  width: 33%;
}

.social {
  text-align: right;
}

.social {
  display: flex;
  flex-direction: row;
}

.socialIcon svg:hover * {
  fill: var(--white);
}

.socialIcon:not(:last-child) {
  margin-right: 24px;
}

.bottomLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bottomLink {
  color: var(--gray);
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  text-align: right;
}

.bottomLink:hover {
  color: var(--white);
}

.bottomLink:not(:last-child) {
  margin-right: 42px;
}

@media screen and (max-width: 1024px) {
  .footer {
    overflow: hidden;
    position: static;
  }

  .top {
    flex-direction: column;
  }

  .top > * {
    width: 100%;
  }

  .top > *:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .bottom {
    flex-direction: column;
  }

  .bottom > * {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }

  .bottom > * {
    margin-bottom: 24px;
  }

  .copyright {
    order: 3;
    margin-bottom: 0;
  }
}
