.button {
  font-size: 12px;
  line-height: 24px;
  height: 58px;
  letter-spacing: 0.5px;
  padding: 16px 36px 16px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 178px;
  font-weight: 500;
}

.icon {
  display: inline-block;
  margin-left: 16px;
}

.button:hover svg {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(4px);
  }
}

/* White */

.button.white {
  border: 1px solid var(--white);
  color: var(--white);
}

.button.white svg * {
  fill: var(--white);
}

.button.white:hover {
  background: var(--white);
  color: var(--blue);
}

.button.white:hover svg * {
  fill: var(--blue);
}

/* Yellow */

.button.yellow {
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-color: transparent;
  background-image: linear-gradient(#000, #000),
  linear-gradient(to left, #f7dd4b, #db7a45);
  background-origin: border-box;
  color: var(--yellow);
  background-clip: padding-box, border-box;
  mix-blend-mode: exclusion;
}

.button.yellow svg * {
  fill: var(--yellow);
}

.button.yellow:hover {
  background: var(--yellow);
  color: var(--white);
  mix-blend-mode: normal;
}

.button.yellow:hover svg * {
  fill: var(--white);
}

/* Green */

.button.green {
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
  linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
}

.button.green:hover {
  background: var(--green);
  color: var(--white);
}

.button.green:hover svg * {
  fill: var(--blue);
}

/* Yellow on white */
/* Dark Yellow on white */

.button.yellowOnWhite, .button.darkYellowOnWhite {
  border: 1.5px solid var(--yellow);
  border-radius: 30px;
  background-color: var(--white);
  /* background-image: linear-gradient(var(--white), var(--white)),
  linear-gradient(to left, #f7dd4b, #db7a45); */
  background-origin: border-box;
  color: var(--yellow);
  background-clip: padding-box, border-box;
}

.button.yellowOnWhite svg *, .button.darkYellowOnWhite svg * {
  fill: var(--yellow);
}

.button.yellowOnWhite:hover, .button.darkYellowOnWhite:hover {
  background: var(--yellow);
  color: var(--white);
  mix-blend-mode: normal;
}

.button.yellowOnWhite:hover svg *, .button.darkYellowOnWhite:hover svg * {
  fill: var(--white);
}

.button.darkYellowOnWhite {
  color: #181B26;
}

/* Yellow SVG */

.button.yellowSVG {
  white-space: nowrap;
  width: 178px;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background: url("./border.svg") center center no-repeat;
  background-size: contain;
  color: var(--yellow);
  background-clip: padding-box, border-box;
}

.button.yellowSVG svg * {
  fill: var(--yellow);
}

.button.yellowSVG:hover {
  background: var(--yellow);
  color: var(--white);
  mix-blend-mode: normal;
}

.button.yellowSVG:hover svg * {
  fill: var(--white);
}

/* White on turquoise */

.button.whiteOnTurquoise {
  border: 1px solid var(--white);
  color: var(--white);
}

.button.whiteOnTurquoise svg * {
  fill: var(--white);
}

.button.whiteOnTurquoise:hover {
  background: var(--white);
  color: #12b391;
}

.button.whiteOnTurquoise:hover svg * {
  fill: #12b391;
}
