.container {
  max-width: 1108px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.containerWide {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 520px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .containerWide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
