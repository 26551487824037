.row {
  display: flex;
}

.sliderWrapper {
  transform: translateY(-34px);
  width: 60% !important;
  min-height: 516px;
}

.slider {
  width: calc(60vw) !important;
}

.content {
  padding-top: 80px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.text {
  margin-bottom: 24px;
}

.content > * {
  max-width: 300px;
}

.news {
  padding-bottom: 112px;
  background: var(--offWhite);
}

.newsWhite {
  overflow: hidden;
  padding-top: 114px;
  background: var(--white);
}

.newsWhite .slider {
  transform: translateY(0);
}

.newsItem {
  height: 512px;
  min-width: 410px;
  max-width: 410px;
  display: flex;
  align-items: flex-end;
}

.newsItem > * {
  transition: transform 0.15s ease-in-out;
}

.newsItem:not(.activeNewsItem) > * {
  display: block;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.activeNewsItem {
  min-width: 410px;
  max-width: 410px;
  transition: background 0.15s ease-in-out;
}

.newsImage {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  margin-bottom: 24px;
}

.newsImage > *:first-child {
}

.labelWrapper {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
}

.newsTop {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 12px;
}

.newsExcerpt {
  margin-bottom: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsAuthor {
  color: var(--lightGray);
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-bottom: 24px;
}

.navigation {
  transform: translateY(153px);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.prev,
.next {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev:hover,
.next:hover {
  border: 1px solid var(--green);
}

.prev svg,
.next svg {
  transition: transform 0.2s ease-in-out;
}

.prev:hover svg {
  transform: translateX(4px);
}
.next:hover svg {
  transform: translateX(4px);
}

.prev {
  transform: rotate(180deg);
  margin-right: 22px;
}

@media screen and (max-width: 1024px) {
  .news,
  .newsWhite {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .row {
    flex-direction: column;
  }
  .content,
  .slider,
  .sliderWrapper {
    padding-top: 0;
    width: 100% !important;
  }

  .sliderWrapper {
    transform: translateY(0);
  }

  .navigation {
    margin-top: 24px;
    margin-bottom: 24px;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .newsImage,
  .newsItem {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }

  .news,
  .newsWhite {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
