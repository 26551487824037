.heading {
  color: var(--black);
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44px;
  margin-bottom: 16px;
}

.headingBig {
  color: var(--black);
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 52px;
}

.headingMediumSmall {
  color: var(--black);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 38px;
  margin-bottom: 16px;
}

.headingSmall {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-bottom: 16px;
}

.headingWhite {
  color: var(--white);
}
