.link {
  color: var(--black);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  appearance: none;
}


.link svg {
  margin-left: 12px;
  transition: 0.2s transform ease-in-out;
}

.link:hover svg {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(8px);
  }
}
